import React, { useState } from "react";
import * as S from "./styles";

const PlanSelect = ({name, selected, plans, onChange}) => {

    const handleChange = value => {
        onChange(name, value);
    };

    const renderStrips = () => {
        return plans && plans.map(plan => {
            return (
                <S.StripContainer type={"button"} key={`plan-${plan.id}`} className={plan.id === selected && 'active'} onClick={() => handleChange(plan.id)}>
                    <S.StripLeft>{plan.name}</S.StripLeft>
                    {'original' in plan && <S.StripCenter>{plan.months}x de {plan.original}</S.StripCenter>}
                    <S.StripRight>{plan.months}x de {plan.price}</S.StripRight>
                </S.StripContainer>
            );
        });
    };

    return (
        <S.Wrapper data-testid="PlanSelect">
            {renderStrips()}
        </S.Wrapper>
    );
};

export default PlanSelect;
