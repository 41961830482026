import React, { useState } from "react";
import BookImage from "Assets/Image/book.png";
import Image from "next/image";
import * as S from "./styles";

const AddonSelect = ({ name, addons, onChange }) => {
    const [addonSelected, setAddonSelected] = useState(null);

    const handleChange = (event) => {
        setAddonSelected(event.target.checked ? event.target.value : null);

        onChange(event.target.name, event.target.value);
    };

    const renderMainStrip = (index, id, price, installments) => {
        return (
            <S.StripContainer
                htmlFor={`addon-main-${index}`}
                className={`${
                    addonSelected == id || addonSelected === null
                        ? "active"
                        : ""
                }`}
            >
                <S.StripIcon
                    className={`${addonSelected == id ? "active" : ""}`}
                />
                <S.StripRadio
                    type={"checkbox"}
                    id={`addon-main-${index}`}
                    name={`${name}.${index}`}
                    value={id}
                    onChange={handleChange}
                />
                <S.StripLabel>
                    Quero adicionar por apenas {installments}x de {price}
                </S.StripLabel>
            </S.StripContainer>
        );
    };

    const renderAddon = (index, item) => {
        return (
            <S.Wrapper data-testid="AddonSelect" key={index}>
                <S.HeaderLine />
                <S.TitleContainer>{item.name}</S.TitleContainer>
                {renderMainStrip(
                    index,
                    item.id,
                    item.has_promotion ? item.special_price : item.normal_price,
                    item.hasOwnProperty("interval_count")
                        ? item.interval_count
                        : 1
                )}
                <S.FooterSection>
                    {item.image && item.image.length > 0 ? (
                        <S.LeftBox>
                            <S.ImageContainer>
                                <Image
                                    src={item.image}
                                    layout="fill"
                                    objectFit={"contain"}
                                    priority={true}
                                    className="icon-image"
                                    alt="Book Image"
                                />
                            </S.ImageContainer>
                        </S.LeftBox>
                    ) : null}
                    <S.RightBox>
                        <S.SaleText
                            dangerouslySetInnerHTML={{ __html: item.notes }}
                        ></S.SaleText>
                        {item.has_promotion ? (
                            <>
                                <S.SpecialSale>
                                    <span>OFERTA ESPECIAL</span>
                                </S.SpecialSale>
                                <S.NormalPrice>
                                    O preço normal é{" "}
                                    {item.hasOwnProperty("interval_count")
                                        ? item.interval_count
                                        : 1}
                                    x de {item.normal_price}
                                </S.NormalPrice>
                            </>
                        ) : null}
                    </S.RightBox>
                </S.FooterSection>
            </S.Wrapper>
        );
    };

    return addons.map((addon, key) => renderAddon(key, addon));
};

export default AddonSelect;
