/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { fetchPlans, fetchTestVoucher } from "Features/Api";
import * as S from "./styles";

const CustomerPlanFields = ({items, addons, fields, onFieldChange, ...props}) => {
    const [visible, setVisible] = useState(false);
    const [loader, setLoader] = useState(false);
    const [voucher, setVoucher] = useState(null);
    const [voucherApplied, setVoucherApplied] = useState(false);
    const [voucherError, setVoucherError] = useState({
        status: false,
        message: ''
    });
    const [plans, setPlans] = useState(items);
    const [plan, setPlan] = useState(null);
    const [addon, setAddon] = useState(null);
    const [totalValue, setTotalValue] = useState('R$0,00');

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const searchVoucher = searchParams.get('voucher');

        if (searchVoucher !== null) {
            setVoucher(searchVoucher);
        }

        if (items.length > 0) {
            if (searchVoucher !== null) {
                handleVoucher(searchVoucher);
            }
        } else {
            fetchPlans()
                .then(response => response.json())
                .then(json => {
                    if (json.status === 200) {
                        let newPlans = json.data.map(item => {
                            return {
                                id: item.id,
                                name: item.name,
                                months: item.interval_count,
                                price: item.price
                            };
                        });
                        handlePlanChange('plan_id', newPlans[0].id);
                        setPlans(newPlans);

                        if (searchVoucher !== null) {
                            handleVoucher(searchVoucher);
                        }
                    }
                })
                .catch(e => {
                    console.log(e);
                });
        }
    }, []);

    useEffect(() => {
        handlePlanSelected(fields.plan_id);
    }, [plans, fields.plan_id]);

    useEffect(() => {
        let val1 = 0, val2 = 0;

        if (plan) val1 = (plan.months * parseFloat(plan.price.replace('R$', '').replace(',', '.')));
        if (addon) {
            let usefulPrice = addon.has_promotion ? addon.special_price : addon.normal_price;
            val2 = parseFloat(usefulPrice.replace('R$', '').replace(',', '.'));
        }

        setTotalValue((val1 + val2).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}).replace(/\s/g, ''));
    }, [plan, addon]);

    const handlePlanSelected = (planId) => {
        setPlan(plans.find(plan => plan.id === planId));
    };

    const handlePlanChange = (field, value) => {
        handlePlanSelected(value);

        onFieldChange(field, value);
    };

    const handleAddonSelected = (addonId) => {
        setAddon(addons.find(addon => addon.id === parseInt(addonId)));
    };

    const handleAddonChange = (field, value) => {
        handleAddonSelected(value);

        onFieldChange(field, value);
    };

    const handleModalClose = (event, reason) => {
        setVisible(false);
    };

    const handleVoucherChange = event => {
        setVoucher(event.target.value);
        setVoucherError({status: false, message: ''});
    };

    const handleVoucher = code => {
        fetchTestVoucher(code)
            .then(response => response.json())
            .then(json => {
                if (json.status === 200 && json.data.plans.length > 0) {
                    setPlans(json.data.plans);
                    setVoucherApplied({
                        name: json.data.discountName,
                        discount: json.data.discountValue,
                    });

                    onFieldChange('voucher', code);
                    handlePlanChange('plan_id', json.data.plans[0].id);
                    setVisible(false);
                } else {
                    setVoucherError({status: true, message: 'Cupom inválido ou expirado'});
                }

                setLoader(false);
            })
            .catch(e => {
                console.log(e);
            });
    }

    const handleApply = () => {
        if (voucher !== null && voucher !== '') {
            setLoader(true);
            setVoucherError({status: false, message: ''});
            handleVoucher(voucher);
        } else {
            setVoucherError({status: true, message: '*Campo obrigatório'});
        }
    };

    const renderModal = () => {
        return (
            <S.CustomModal isOpen={visible} onRequestClose={handleModalClose}>
                <S.CardContainer>
                    <S.CardCloseButton onClick={() => setVisible(false)} />
                    <S.Row container justifyContent={'center'} alignItems={'center'} className={'space-bottom'}>
                        <S.Col item xs={12}>
                            <S.CardTitle>Cupom de Desconto</S.CardTitle>
                            <S.CardSubtitle>Tem um cupom de desconto? Insira na linha abaixo e clique em {'"aplicar"'}.</S.CardSubtitle>
                        </S.Col>
                    </S.Row>
                    <S.Row container justifyContent={'center'} alignItems={'center'} className={'space-bottom'}>
                        <S.Col item xs={12}>
                            <S.CustomInput mode={"custom"} type="text" name="voucher" defaultValue={voucher} onChange={handleVoucherChange} hasError={voucherError.status} helperText={voucherError.message} />
                        </S.Col>
                    </S.Row>
                    <S.Row container justifyContent={'center'} alignItems={'center'}>
                        <S.Col item xs={12}>
                            <S.CustomButton type={"button"} loading={loader} onClick={handleApply}>APLICAR</S.CustomButton>
                        </S.Col>
                    </S.Row>
                </S.CardContainer>
            </S.CustomModal>
        );
    };

    return (
        <S.Wrapper data-testid="CustomerPlanFields">
            <S.Row container justifyContent={'center'} alignItems={'center'} className={'space-bottom'}>
                <S.Col item xs={12}>
                    <S.CustomStepTitle number={1} title={"Escolha seu plano"} />
                </S.Col>
            </S.Row>
            <S.Row container justifyContent={'center'} alignItems={'center'}>
                <S.Col item xs={12}>
                    {plans.length > 0 && <S.CustomPlanSelect name={"plan_id"} plans={plans} selected={plan?.id} onChange={handlePlanChange} />}
                </S.Col>
            </S.Row>
            <S.Row container justifyContent={'center'} alignItems={'center'}>
                <S.Col item xs={12} className={'text-center'}>
                    {voucherApplied ? (
                        <S.ContainerVoucher>
                            <S.Row container justifyContent={'space-between'} alignItems={'flex-end'} className={'space-down'}>
                                <S.VoucherCaption>CUPOM APLICADO</S.VoucherCaption>
                                <S.VoucherLink onClick={() => setVisible(true)}>trocar</S.VoucherLink>
                            </S.Row>
                            <S.Row container justifyContent={'space-between'} alignItems={'center'}>
                                <S.VoucherName>{voucherApplied.name}</S.VoucherName>
                                <S.VoucherDiscount>- {voucherApplied.discount}</S.VoucherDiscount>
                            </S.Row>
                        </S.ContainerVoucher>
                    ) : (
                        <S.CustomLink onClick={() => setVisible(true)}>Tem cupom de desconto?</S.CustomLink>
                    )}
                </S.Col>
            </S.Row>
            <S.Row container justifyContent={'center'} alignItems={'center'}>
                <S.Col item xs={12}>
                    {addons.length > 0 &&  <S.CustomAddonSelect name={"addon"} addons={addons} onChange={handleAddonChange} />}
                </S.Col>
            </S.Row>
            {addon && (<S.Row container justifyContent={'center'} alignItems={'center'} className={'total-margin'}>
                <S.Col item xs={12}>
                    <S.TotalContainer>
                        <S.TotalTitle>Resumo da sua compra</S.TotalTitle>
                    </S.TotalContainer>
                    {plan && (
                        <S.TotalContainer>
                            <S.TotalLeft>{plan.name}</S.TotalLeft>
                            <S.TotalRight>{plan.months}x de {plan.price}</S.TotalRight>
                        </S.TotalContainer>
                    )}
                    <S.TotalContainer>
                        <S.TotalLeft>{addon.product_name}</S.TotalLeft>
                        <S.TotalRight>{addon.hasOwnProperty('interval_count') ? addon.interval_count : 1}x de {addon.has_promotion ? addon.special_price : addon.normal_price}</S.TotalRight>
                    </S.TotalContainer>
                    <S.TotalContainer className={"no-divisor"}>
                        <S.TotalTitle>Total Geral</S.TotalTitle>
                        {plan && (
                            <S.TotalTitle>{totalValue}</S.TotalTitle>
                        )}
                    </S.TotalContainer>
                </S.Col>
            </S.Row>)}
            {renderModal()}
        </S.Wrapper>
    );
};

export default CustomerPlanFields;
