import styled from 'styled-components'

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
`;

export const StripContainer = styled.button`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-height: 42px;
    padding: 11px 30px;
    margin-bottom: 15px;
    background: #fff;
    border: 1px solid #6B6B6B;
    border-radius: 30px;
    cursor: pointer;
    overflow: hidden;
    flex-wrap: wrap;

    &.active {
        background-image: linear-gradient(to right, #5F00AC, #5F00AC 11.73%, #F637E2);
        border: 1px solid #edf2f4;
        div {
            color: #fff !important;
        }
    }
`;

export const StripLeft = styled.div`
    font-family: 'Quicksand', sans-serif;
    font-size: 1.1rem;
    font-weight: bold;
    line-height: 1.3rem;
    text-transform: capitalize;
    text-align: start;
    white-space: break-spaces;
    color: #5E00AC;

    @media ${(props) => props.theme.device.laptop} {
        font-size: 1rem;
        line-height: 1.2rem;
    }
`;

export const StripCenter = styled.div`
    font-family: sans-serif;
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.2rem;
    text-decoration-line: line-through;
    text-align: center;
    white-space: break-spaces;
    opacity: 0.4;
    color: #000;

    @media ${(props) => props.theme.device.laptop} {
        font-size: 0.9rem;
        line-height: 1.1rem;
    }
`;

export const StripRight = styled.div`
    font-family: sans-serif;
    font-size: 1rem;
    line-height: 1.2rem;
    white-space: break-spaces;
    text-align: end;
    color: #575757;

    @media ${(props) => props.theme.device.laptop} {
        font-size: 1rem;
        line-height: 1.2rem;
    }
`;
