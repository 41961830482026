import styled from "styled-components";
import ArrowIcon from "Assets/Image/right-arrow.png";
import CorrectIcon from "Assets/Image/correct.png";

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: relative;
    background: #fbefff;
    border-radius: 6px;
    padding: 0px 20px 25px;
    margin-top: 20px;
    overflow: hidden;
`;

export const HeaderLine = styled.div`
    display: flex;
    width: 100%;
    height: 6px;
    top: 0;
    left: 0;
    position: absolute;
    background: linear-gradient(90.97deg, #6000ac 0%, #f637b2 104.61%);
`;

export const TitleContainer = styled.div`
    font-family: "Quicksand", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.2rem;
    color: #5e00ac;
    margin-top: 25px;
    margin-bottom: 20px;

    @media ${(props) => props.theme.device.tablet} {
    }
`;

export const StripContainer = styled.label`
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
    padding: 10px 20px;
    margin-bottom: 10px;
    background: transparent;
    border: 1px solid #ce96f4;
    border-radius: 6px;
    cursor: pointer;

    &.active {
        background: #fff;
    }
`;

export const StripIcon = styled.span`
    background-image: url(${ArrowIcon});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 18px;
    height: 100%;

    &.active {
        background-image: url(${CorrectIcon});
    }

    &.hidden {
        visibility: hidden;
    }
`;

export const StripLabel = styled.div`
    font-family: sans-serif;
    font-size: 0.9rem;
    font-weight: bold;
    line-height: 1.1rem;
    color: #5e00ac;
    max-width: 75%;

    @media ${(props) => props.theme.device.laptop} {
        font-size: 1rem;
        line-height: 1.2rem;
    }
`;

export const StripRadio = styled.input`
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: inline-block;
    width: 15px;
    height: 15px;
    padding: 1.9px;
    margin: 0px;
    margin-left: 10px;
    margin-right: 15px;
    background-clip: content-box;
    border: 1px solid #ce96f4;
    background-color: #fff;
    // border-radius: 50%;
    cursor: pointer;

    &:checked {
        background-color: #5e00ac;
        border-width: 2px;
        border-color: #5e00ac;
    }
`;

export const StripRight = styled.div`
    font-family: sans-serif;
    font-size: 1.2rem;
    line-height: 1.4rem;
    color: #575757;

    @media ${(props) => props.theme.device.laptop} {
        font-size: 1rem;
        line-height: 1.2rem;
    }
`;

export const FooterSection = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: relative;
    margin-top: 15px;
`;

export const LeftBox = styled.div`
    display: flex;
    width: 45%;
    position: relative;
`;

export const ImageContainer = styled.div`
    display: flex;
    top: -20%;
    left: -5px;
    width: 100%;
    height: 140%;
    position: relative;
`;

export const RightBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 55%;
    position: relative;
`;

export const SaleText = styled.div`
    font-family: sans-serif;
    font-size: 0.8rem;
    line-height: 1rem;
    color: #5e00ac;
    margin-bottom: 15px;

    @media ${(props) => props.theme.device.laptop} {
        font-size: 0.9rem;
        line-height: 1.1rem;
    }
`;

export const SpecialSale = styled.div`
    margin-bottom: 10px;

    span {
        font-family: sans-serif;
        font-size: 0.8rem;
        font-weight: bold;
        line-height: 1rem;
        color: #f637b2;
        padding-bottom: 2px;
        border-bottom: 2px solid #f637b2;
    }

    @media ${(props) => props.theme.device.laptop} {
        font-size: 0.9rem;
        line-height: 1.1rem;
    }
`;

export const NormalPrice = styled.div`
    font-family: sans-serif;
    font-weight: bold;
    font-size: 0.8rem;
    line-height: 1rem;
    color: #5e00ac;

    @media ${(props) => props.theme.device.laptop} {
        font-size: 0.9rem;
        line-height: 1.1rem;
    }
`;
