import styled from 'styled-components';
import ClientOnlyPortal from 'Components/Atoms/ClientOnlyPortal';

export const CustomPortal = styled(ClientOnlyPortal)``;

export const Backdrop = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.6);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1001;
`;
