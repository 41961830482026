import styled from 'styled-components';
import Grid from "Components/Atoms/Grid";
import Modal from "Components/Atoms/Modal";
import StepTitle from "Components/Atoms/StepTitle";
import PlanSelect from "Components/Atoms/PlanSelect";
import AddonSelect from "Components/Atoms/AddonSelect";
import Input from "Components/Atoms/Input";
import Button from "Components/Atoms/Button";

export const Col = styled(Grid)``;
export const Row = styled(Grid)``;

export const Wrapper = styled.div`
    padding: 0px 25px;

    @media ${(props) => props.theme.device.tablet} {
        padding: 25px 30px 10px;
    }

    .space-bottom {
        margin-bottom: 10px;
    }

    .space-down {
        margin-bottom: 15px;
    }

    .total-margin {
        margin-top: 25px;
    }

    .text-center {
        text-align: center;
    }
`
export const CustomModal = styled(Modal)``;

export const CustomStepTitle = styled(StepTitle)``;

export const CustomPlanSelect = styled(PlanSelect)``;

export const CustomAddonSelect = styled(AddonSelect)``;

export const CustomLink = styled.a`
    display: inline-flex;
    font-family: system-ui;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.4rem;
    color: #9f9f9f;
    cursor: pointer;
    margin-top: 20px;

    @media ${(props) => props.theme.device.tablet} {
        font-size: 0.9rem;
        line-height: 1.1rem;
    }
`;

export const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 85%;
    max-width: 450px;
    min-height: auto;
    padding: 22px;
    border-radius: 2px;
    box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%);
    background-color: #fff;

    @media ${(props) => props.theme.device.tablet} {
        width: 100%;
    }
`;

export const CardCloseButton = styled.a`
    position: absolute;
    top: 12px;
    right: 12px;
    height: 15px;
    width: 15px;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMC40MDcyMjciIHk9IjEyLjI5NzkiIHdpZHRoPSIxNy4wMzgyIiBoZWlnaHQ9IjIuMTg0OTMiIHJ4PSIxLjA5MjQ3IiB0cmFuc2Zvcm09InJvdGF0ZSgtNDUgMC40MDcyMjcgMTIuMjk3OSkiIGZpbGw9IiM3MzczNzMiLz4KPHJlY3QgeD0iMTIuNDU1MSIgeT0iMTMuODQyOCIgd2lkdGg9IjE3LjAzODIiIGhlaWdodD0iMi4xODQ5MyIgcng9IjEuMDkyNDciIHRyYW5zZm9ybT0icm90YXRlKC0xMzUgMTIuNDU1MSAxMy44NDI4KSIgZmlsbD0iIzczNzM3MyIvPgo8L3N2Zz4K);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border: none;
    cursor: pointer;
`;

export const CardTitle = styled.h1`
    font-family: 'Quicksand', sans-serif;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.7rem;
    margin-bottom: 10px;
    color: #5e00ac;
`;

export const CardSubtitle = styled.h3`
    font-family: system-ui, sans-serif;
    font-size: 1.2rem;
    font-weight: normal;
    line-height: 1.4rem;
    margin-bottom: 30px;
    color: #8b8b8b;

    @media ${(props) => props.theme.device.tablet} {
        font-size: 0.9rem;
        line-height: 1.1rem;
    }
`;

export const CustomInput = styled(Input)``;

export const CustomButton = styled(Button)`
    background: #5e00ac;
    padding: 10px 0px;

    @media ${(props) => props.theme.device.tablet} {
        font-size: 1rem;
    }
`;

export const ContainerVoucher = styled.div`
    display: flex;
    flex-direction: column;
    padding: 30px 20px;
    background: rgba(0,204,144,0.05);
    border-radius: 7px;
`;

export const VoucherCaption = styled.div`
    font-family: system-ui;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.2rem;
    color: #00C289;
`;

export const VoucherLink = styled.a`
    display: inline-flex;
    font-family: system-ui;
    font-size: 0.9rem;
    font-weight: 500;
    line-height: 1.2rem;
    text-decoration-line: underline;
    color: #00C289;
    cursor: pointer;
`;

export const VoucherName = styled.div`
    font-family: system-ui;
    font-size: 1.1rem;
    font-weight: normal;
    line-height: 1.3rem;
    color: #00C289;
`;

export const VoucherDiscount = styled.div`
    font-family: system-ui;
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1.4rem;
    color: #00C289;
`;

export const TotalContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px 30px;
    background: #fff;
    border: none;
    border-bottom: 1px solid #BABABA;

    &.no-divisor {
        border-bottom: none;
    }
`;

export const TotalLeft = styled.div`
    font-family: sans-serif;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1rem;
    text-transform: capitalize;
    color: #575757;

    @media ${(props) => props.theme.device.laptop} {
        font-size: 0.9rem;
        line-height: 1.1rem;
    }
`;

export const TotalTitle = styled(TotalLeft)`
    font-weight: 700;
`;

export const TotalRight = styled.div`
    font-family: system-ui;
    font-size: 0.8rem;
    font-weight: 600;
    line-height: 1rem;
    white-space: nowrap;
    color: #575757;

    @media ${(props) => props.theme.device.laptop} {
        font-size: 0.9rem;
        line-height: 1.1rem;
    }
`;
