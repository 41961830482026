import * as S from "./styles";

const Modal = ({isOpen = false, onRequestClose, children, ...props}) => {

    const handleBackdropClick = event => {
        if (event.target.id === 'modal-backdrop') onRequestClose();
    };

    return isOpen &&
        <S.CustomPortal selector="#modal-root">
          <S.Backdrop id="modal-backdrop" onClick={handleBackdropClick}>
            <style jsx>{`
                :global(body) {
                    padding-right: 17px;
                    overflow: hidden;
                }
                `}</style>
            {children}
          </S.Backdrop>
        </S.CustomPortal>;
};

export default Modal;
